@import '../../global.scss';

.mainFooter {
    gap: 5px;
    width: 100%;
    display: flex;
    color: $white;
    align-items: center;
    padding: 20px 80px 10px;
    flex-direction: column;
    justify-content: space-between;
    background-color: $mainGreen;
   
    @include large {
        padding: 10px 40px;
    }

    @include tablet {
        padding: 10px 30px;
    }

    @include mobile {
        padding: 10px 20px;
    }

    .a-link{
        text-decoration: none;
        color: inherit;
    }



    .link {
        text-decoration: none;
        color: inherit;
    }

    .footerTop {
        width: 100%;
        display: grid;
        grid-gap: 100px;
        padding: 10px 0px;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(3, 1fr);

        @include large {
            grid-gap: 30px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            padding: 35px 0px;
        }

        @include mobile {
            grid-gap: 10px;
        }

        .item {
            gap: 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h3 {
                font-size: 18px;
                margin-top: 5px;
                letter-spacing: 1px;
                font-family: Futura-Regular;
            }

            span {
                font-size: 15px;
                font-weight: 300;
                font-family: Futura-Light;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include tablet {
                gap: 8px;
            }

        }

        .item2 {
            gap: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h3 {
                font-size: 18px;
                margin-top: 5px;
                letter-spacing: 1px;
                font-family: Futura-Regular;
            }

            span {
                font-size: 14px;
                font-weight: 300;
                font-family: Futura-Light;

                &:hover {
                    text-decoration: underline;
                }
            }

            .itemWrapper {
                gap: 15px;
                display: flex;
                align-items: center;

                .icon {
                    font-size: 20px;
                    margin-top: -5px;

                    @include large{
                        margin-top: 0px;
                    }
                }

                .text {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 10px;

                    @include mobile {
                        margin-bottom: 0px;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                    }

                    h3 {
                        font-size: 14px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                    }

                    .address {
                        font-size: 13px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                    }

                    p {
                        font-size: 13px;
                        color: $gray;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                    }
                }
            }

            @include tablet {
                gap: 8px;
            }

        }

    }

    .hrWrapper {
        width: 100%;
        display: flex;
        padding: 10px 0px;

        @include tablet {
            padding: 0px 0px;
        }

        hr {
            width: 100%;
            border: 0.01px solid gray;
        }
    }

    .footerBottom {
        gap: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .top {
            width: 100%;
            gap: 5px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            h3{
                font-weight: 300;
                font-size: 15px;

                @include mobile{
                    font-size: 16.5px;

                }
            }
            p {
                font-size: 13.5px;
                color: $gray;
                font-family: Futura-Light;
            }

            @include large{
                margin-top: 10px;
                align-items: center;
            }

        }

        .bottom {
            gap: 5px;
            width: 100%;
            display: flex;
            color: $white;
            padding: 10px 0px;
            align-items: center;
            justify-content: space-between;

            @include large {
                gap: 10px;
                flex-direction: column-reverse;
            }


            .left {
                gap: 5px;
                display: flex;
                font-size: 14px;
                align-items: center;

                @include phone{
                    margin-bottom: 20px;
                }

                span {
                    font-size: 14px;
                    font-family: Futura-Light;
                    @include mobile {
                        font-size: 12px;
                    }
                }
            }

            .right {
                gap: 5px;
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                    margin-right: 10px;
                    font-family: Futura-Light;
                }

                .rightItem {
                    padding: 8px;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    margin-right: 10px;
                    color: $mainPink;
                    // background-color: $mainPink;
                    border: 1px solid $white;
                    background-color: $white;

                    @include mobile {
                        padding: 6px;
                    }

                    &:hover {
                        color: $white;
                        border: 1px solid $white;
                        background-color: $mainPink;
                    }

                    .icon {
                        font-size: 14px;

                        @include mobile {
                            font-size: 13px;
                        }
                    }
                }
            }

        }

    }
}