@import '../../global.scss';

//////// SHEBOSS ///////////
.sheboss {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // background-color: $white;

    .shebossHero {
        width: 100%;
        height: 400px;
        display: flex;
        position: relative;

        @include mobile {
            height: 320px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        .top {
            gap: 5px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.45);


            h2 {
                font-size: 45px;
                color: $offWhite;
                letter-spacing: 0.8px;
                font-family: Futura-Medium;

                @include tablet {
                    letter-spacing: 1px;
                }

                @include mobile {
                    font-size: 40px;
                    letter-spacing: 1.5px;
                }
            }

            p {
                font-size: 20px;
                color: $white;
                font-weight: 300;
                letter-spacing: 1.2px;
                font-family: Futura-Light;

                @include phone {
                    font-size: 21px;
                }

                @include mobile {
                    letter-spacing: 0.3px;
                }
            }
        }

    }

    .headingText {
        gap: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 60px 180px 60px;
        flex-direction: column;
        justify-content: center;

        @include extralarge {
            padding: 60px 60px 60px;
        }

        @include tablet {
            padding: 60px 30px 60px;
        }

        @include mobile {
            padding: 20px 20px 60px;
        }

        span {
            font-size: 21.5px;
            line-height: 1.5;
            color: $black2;
            font-weight: 300;
            text-align: center;
            letter-spacing: 0.5px;

            @include mobile {
                font-size: 18px;
                text-align: left;
            }

            @include phone {
                font-size: 20px;
            }
        }

        hr {
            width: 100%;
            display: flex;
            border: 4px solid $lightGreen;
        }

        .numbers{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .item{
                gap: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h3 {
                    font-size: 44px;
                    color: $mainGreen;
                    @include mobile{
                        font-size: 35px;
                    }
                    @include phone{
                        font-size: 35px;
                    }
                }


                span {
                    font-size: 13px;
                    color: $mainGreen;
                    font-weight: 400;
                    letter-spacing: 1.3px;
                    font-family: 'Roboto', sans-serif;
                    @include mobile{
                        font-size: 11px;
                    }
                }
            }
        }

    }


    .literacyModules {
        display: flex;
        padding: 60px 100px 80px;
        flex-direction: column;

        @include extralarge {
            padding: 60px 50px;
        }

        @include large {
            padding: 40px 30px;
        }

        @include tablet {
            padding: 0px 15px 20px;
        }

        @include phone {
            padding: 50px 10px 0px;
        }

        h1 {
            font-size: 28px;
            margin-bottom: 10px;
            letter-spacing: 0.5px;
            color: $mainGreen;
            // font-family: Futura-Medium;

            @include mobile {
                font-size: 28px;
            }
        }

    }

    .courseModules {
        gap: 25px;
        display: flex;
        margin-top: 10px;
        flex-direction: column;

        .listWrapper {
            gap: 15px;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            align-items: center;

            @include tablet {
                gap: 5px;
            }

        }

        .infoContainer {
            border: none;
            min-height: 350px;
            padding: 30px 30px;
            background-color: $lightGreen;

            @include mobile {
                padding: 30px 20px;
            }
        }
    }

    .business {
        gap: 10px;
        display: flex;
        padding: 100px 100px 140px;
        background-color: $lightPink;

        background-color: $cream2;

        @include extralarge {
            padding: 80px 50px 100px;
        }

        @include large {
            gap: 50px;
            flex-direction: column;
            padding: 50px 100px 150px;
        }

        @include tablet {
            padding: 50px 30px 80px;
        }

        @include mobile {
            gap: 40px;
            padding: 50px 20px 80px;
        }

        .left {
            flex: 1;
            gap: 10px;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 28px;
                margin-bottom: 10px;
                letter-spacing: 0.5px;
                color: $mainPink;
                // font-family: Futura-Medium;

                @include mobile {
                    font-size: 28px;
                }
            }

            span {
                line-height: 1.4;
                font-size: 17.5px;
                font-weight: 300;
                color: $darkBlack;
                margin-right: 50px;
                letter-spacing: 0.4px;
                font-family: Futura-Light;

                @include large {
                    margin-right: 0px;
                }

                @include mobile {
                    font-size: 17px;
                }

                @include phone {
                    font-size: 18.5px;
                }
            }

            .items {
                gap: 15px;
                display: flex;
                margin-top: 10px;
                padding-right: 50px;
                align-items: flex-start;
                flex-direction: column;

                .info-item {
                    gap: 10px;
                    display: flex;
                    width: 100%;
                    align-items: center;


                    .icon {
                        flex: 0.1;
                        font-size: 16.5px;
                        color: $mainPink;
                    }

                    p {
                        flex: 1.8;
                        font-size: 16.5px;
                        color: $darkBlack;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;

                        @include large {
                            // font-size: 14px;
                        }
                    }

                }

            }

        }

        .bssRight {
            flex: 1.3;
            gap: 10px;
            width: 100%;
            display: flex;
            position: relative;
            flex-direction: column;

            @include tablet {
                gap: 50px;
            }

            @include mobile {
                gap: 40px;
            }

            .item1 {
                flex: 1;
                gap: 10px;
                display: flex;

                img {
                    flex: 2.5;
                    height: 100%;
                    width: 100%;
                    height: 380px;
                    border-radius: 20px;
                    border: 3px #f3ebe8 solid;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    @include large {
                        // flex: 2;
                        height: 430px;
                    }

                    @include tablet {
                        width: 100%;
                        height: 100%;
                    }

                }

                .empty {
                    flex: 1;
                    object-fit: cover;

                    @include tablet {
                        display: none;
                    }

                }
            }

            .item2 {
                flex: 1;
                gap: 10px;
                width: 100%;
                width: 280px;
                display: flex;
                align-self: flex-end;
                margin-top: 160px;
                position: absolute;

                @include tablet {
                    display: none;
                }

                .modules {
                    flex: 1;
                    width: 300px;
                    display: flex;
                    object-fit: cover;
                    padding: 20px;
                    flex-direction: column;
                    background-color: $mainPink;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                    border: 2px #f3ebe8 solid;
                    border-bottom: 0;
                    background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="none" stroke="%23ededed" stroke-width="3" vector-effect="non-scaling-stroke"/></svg>');
                    background-position: bottom left;
                    background-size: 7% auto;
                    background-repeat: repeat-x;

                    @include mobile {
                        width: 170px;
                    }

                    h4 {
                        font-size: 22px;
                        margin-bottom: 10px;
                        letter-spacing: 0.5px;
                        color: $white;
                        // font-family: Futura-Regular;


                        @include tablet {
                            font-size: 25px;
                        }

                    }

                    ul {
                        gap: 15px;
                        display: flex;
                        margin-top: 5px;
                        margin-left: 15px;
                        margin-bottom: 10px;
                        flex-direction: column;

                        li {
                            color: $white;
                            font-size: 15.5px;
                            line-height: 1.5;
                            letter-spacing: 0.5px;
                            font-family: Futura-Light;

                        }

                    }

                }

            }

            //responsive
            .itemRes {
                display: none;

                @include tablet {
                    display: flex;

                    .modules {
                        flex: 1;
                        display: flex;
                        object-fit: cover;
                        padding: 30px 40px;
                        flex-direction: column;
                        background-color: $mainPink;
                        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                        border-top-right-radius: 20px;
                        border-top-left-radius: 20px;

                        border: 2px $lightPink solid;
                        border-bottom: 0;
                        background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="none" stroke="%23ededed" stroke-width="3" vector-effect="non-scaling-stroke"/></svg>');
                        background-position: bottom left;
                        background-size: 4% auto;
                        background-repeat: repeat-x;

                        @include mobile {
                            padding: 20px 30px;
                        }

                        h4 {
                            color: $white;
                            font-size: 20px;
                            margin-bottom: 30px;
                            letter-spacing: 0.5px;

                            @include tablet {
                                font-size: 25px;
                            }

                        }

                        ul {
                            gap: 18px;
                            display: flex;
                            margin-top: 5px;
                            margin-left: 15px;
                            margin-bottom: 30px;
                            flex-direction: column;

                            li {
                                color: $white;
                                font-size: 15.5px;
                                font-weight: 300;
                                line-height: 1.5;
                                letter-spacing: 0.5px;
                                font-family: 'Roboto', sans-serif;

                                @include mobile {
                                    font-size: 14.5px;
                                }
                                @include phone {
                                    font-size: 15.5px;
                                }
                            }

                        }

                    }

                }

            }
        }

    }

}


//////// DEVELOPERS ////////
.developers {
    width: 100%;
    display: flex;
    flex-direction: column;

    .devHero {
        width: 100%;
        height: 380px;
        display: flex;
        position: relative;

        @include mobile {
            height: 300px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        .top {
            gap: 5px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.65);

            h2 {
                font-size: 45px;
                color: $offWhite;
                letter-spacing: 0.8px;
                font-family: Futura-Medium;

                @include tablet {
                    font-size: 40px;
                }

                @include mobile {
                    font-size: 32px;
                }
                @include phone {
                    font-size: 32px;
                }
            }

            p {
                font-size: 22px;
                color: $white;
                font-weight: 300;
                letter-spacing: 1.2px;
                font-family: Futura-Light;

                @include tablet {
                    letter-spacing: 1.4px;
                }

                @include mobile {
                    font-size: 18px;
                    letter-spacing: 0.8px;
                }
                @include phone {
                    font-size: 19px;
                }
            }
        }
    }

    .detailsWrapper {
        gap: 50px;
        display: flex;
        padding: 60px 80px 100px 80px;

        @include extralarge {
            gap: 30px;
            padding: 60px 50px 100px 50px;
        }

        @include large {
            gap: 70px;
            flex-direction: column;
        }

        @include tablet {
            padding: 60px 30px 100px 30px;
        }

        @include mobile {
            padding: 30px 20px 100px 20px;
        }

        @include phone {
            padding: 30px 15px 100px 15px;
        }


        .left {
            flex: 2;
            gap: 40px;

            .items {
                gap: 30px;
                display: flex;
                margin-bottom: 30px;
                flex-direction: column;

                .itemTop {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    h3 {
                        font-size: 26px;
                        line-height: 1.2;
                        font-weight: 300;
                        letter-spacing: 0.6px;
                        color: $blackHeading;
                        margin-bottom: 20px;
                        // font-family: Futura-Medium;
                    }

                    span {
                        font-size: 17px;
                        line-height: 1.5;
                        letter-spacing: 0.5px;
                        color: $darkBlack;
                        font-family: Futura-Light;
                        @include phone{
                            font-size: 18.5px;
                        }
                    }
                }

                .itemBottom {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-radius: 10px;
                        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                        @include large {
                            width: 100%;
                            height: 400px;
                            object-fit: cover;
                        }

                        @include tablet {
                            height: 300px;
                        }

                        @include mobile {
                            height: 250px;
                        }

                        @include phone {
                            height: 220px;
                        }


                    }
                }

            }

            .compHeading {
                display: flex;
                margin-top: 50px;
                margin-bottom: 40px;

                @include large {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
                @include tablet {
                    margin-bottom: 50px;
                }
                @include mobile {
                    margin-top: 30px;
                }

                h3 {
                    font-size: 26px;
                    line-height: 1.2;
                    font-weight: 300;
                    letter-spacing: 0.6px;
                    color: $blackHeading;
                }

            }

            .compWrapper {
                gap: 70px;
                display: flex;
                flex-direction: column;

                @include tablet {
                    gap: 70px;
                }

                .components {
                    gap: 20px;
                    display: flex;

                    @include large {
                        gap: 50px;
                    }

                    @include tablet {
                        gap: 30px;
                        margin-top: -20px;
                        flex-direction: column;
                    }

                    .compLeft {
                        flex: 1.3;
                        gap: 10px;
                        display: flex;
                        flex-direction: column;

                        h3 {
                            font-size: 18px;
                            line-height: 1.2;
                            letter-spacing: 0.8px;
                            color: $blackHeading;
                            // font-family: Futura-Medium;

                            @include mobile {
                                font-weight: 400;
                            }
                            @include phone {
                                font-size: 20px;
                            }

                        }

                        span {
                            font-size: 17px;
                            line-height: 1.5;
                            letter-spacing: 0.5px;
                            color: $darkBlack;
                            font-family: Futura-Light;

                            @include phone {
                                font-size: 18.5px;
                            }
                        }

                    }

                    .compRight {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        img {
                            width: 300px;
                            height: 190px;
                            border: none;
                            border-radius: 10px;
                            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                            @include large {
                                width: 100%;
                                height: 240px;
                                object-fit: cover;
                            }

                            @include tablet {
                                height: 300px;
                            }

                            @include mobile {
                                height: 260px;
                            }


                        }
                    }

                }

            }
        }

        .right {
            flex: 1;
            gap: 30px;
            display: flex;
            flex-direction: column;
            padding: 20px 40px 20px 20px;
            background-color: $lightPink;

            // .selection {
            //     gap: 10px;
            //     display: flex;
            //     align-items: center;
            //     margin-right: 30px;
            //     padding: 10px 20px;
            //     color: $white;
            //     background-color: $mainGreen;

            //     h3 {
            //         font-weight: 400;
            //         font-size: 17px;
            //         letter-spacing: 0.5px;
            //     }

            //     .secIcon {
            //         font-size: 22px;
            //     }
            // }

            .criteria {
                gap: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;

                h3 {
                    font-weight: 400;
                    font-size: 18.5px;
                    letter-spacing: 1px;
                    color: $mainGreen;
                    margin-right: 30px;
                    border-bottom: 3px solid $mainGreen;
                }

                span {
                    font-size: 16px;
                    line-height: 1.3;
                    margin-top: 10px;
                    color: $blackHeading;
                }

            }

            .highlight {
                gap: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 19.5px;
                    letter-spacing: 1px;
                    color: $mainPink;
                    font-weight: 300;
                    margin-right: 30px;
                    // font-family: Futura-Regular;
                    border-bottom: 2px solid $mainPink;
                }

                .highItem {
                    gap: 5px;
                    display: flex;
                    flex-direction: column;

                    .title {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        h4 {
                            font-size: 16.5px;
                            font-weight: 300;
                            letter-spacing: 0.4px;
                            color: $blackHeading;
                        }

                        .highIcon {
                            font-size: 14px;
                            color: $black;

                            @include phone {
                                // font-size: 16.5px;
                            }
                        }
                    }

                    span {
                        font-size: 15.5px;
                        line-height: 1.4;
                        letter-spacing: 0.5px;
                        color: $black2;
                        font-family: Futura-Light;

                        @include phone {
                            font-size: 18px;
                        }
                    }

                }

            }

            .whoAttends {
                gap: 15px;
                width: 100%;
                display: flex;
                flex-direction: column;

                h3 {
                    font-weight: 400;
                    font-size: 19.5px;
                    letter-spacing: 1px;
                    color: $blackHeading;
                    color: $mainPink;
                    margin-right: 10px;
                    // font-family: Futura-Regular;
                    border-bottom: 2px solid $mainPink;
                }

                span {
                    font-size: 15.5px;
                    line-height: 1.4;
                    letter-spacing: 0.5px;
                    color: $black2;
                    font-family: Futura-Light;

                    @include mobile{
                        font-size: 16.5px;
                    }
                    @include phone{
                        font-size: 18px;
                    }
                }

                .list {
                    gap: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    h4 {
                        flex: 1.8;
                        font-size: 15px;
                        letter-spacing: 0.3px;
                        color: $black2;
                        font-family: Futura-Light;

                        @include phone{
                            font-size: 18px;
                        }
                    }

                    .highIcon {
                        flex: 0.1;
                        font-size: 14px;
                        color: $mainBlack;
                    }
                }

            }

            p {
                font-size: 14.5px;
                line-height: 1.3;
                margin-top: 10px;
                color: $darkBlack;
                margin-bottom: 10px;
                letter-spacing: 0.5px;
                font-family: Futura-Regular;
                @include phone{
                    font-size: 15px;
                }
            }

        }
    }

}


//////// SAVINGS ///////////
.savings {
    width: 100%;
    display: flex;
    flex-direction: column;

    .saveHero {
        width: 100%;
        height: 380px;
        display: flex;
        position: relative;

        @include mobile {
            height: 300px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        .top {
            gap: 15px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.68);

            h2 {
                font-size: 45px;
                color: $offWhite;
                letter-spacing: 0.8px;
                font-family: Futura-Medium;

                // @include tablet {
                //     font-size: 40px;
                // }

                // @include mobile {
                //     font-size: 34px;
                //     letter-spacing: 1px;
                // }

                @include tablet {
                    font-size: 40px;
                }

                @include mobile {
                    font-size: 32px;
                    letter-spacing: 1px;
                }
                @include phone {
                    font-size: 29px;
                }
            }

            p {
                font-size: 19px;
                color: $white;
                font-weight: 300;
                letter-spacing: 1.2px;
                font-family: Futura-Light;

                @include tablet {
                    font-size: 17.5px;
                    letter-spacing: 1px;
                }

                @include mobile {
                    font-size: 16.5px;
                    letter-spacing: 0.3px;
                }
                @include phone {
                    font-size: 15.5px;
                    letter-spacing: 0.3px;
                }
            }
        }
    }

    .headingText {
        gap: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 80px 220px 80px;
        flex-direction: column;
        justify-content: center;


        @include extralarge {
            padding: 60px 50px;
        }

        @include large {
            padding: 40px 50px;
        }

        @include tablet {
            padding: 60px 50px 40px;
        }

        @include mobile {
            gap: 20px;
            padding: 60px 20px;
        }
        @include phone {
            padding: 60px 10px;
        }

        .logoWrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .logoImage {
                width: 40px;
                margin-top: 5px;
            }

            .logoText {
                color: #118C4F;
                display: flex;
                font-size: 40px;
                letter-spacing: -1px;
                font-family: 'DM Serif Display', serif;
            }

        }

        span {
            font-size: 19px;
            line-height: 1.5;
            color: $black2;
            font-weight: 300;
            text-align: center;
            letter-spacing: 0.5px;

            @include mobile {
                font-size: 18px;
            }

            @include phone {
                font-size: 22px;
            }

        }

    }

    .regonow {
        gap: 60px;
        display: flex;
        padding: 80px 0px;
        margin-bottom: 100px;
        flex-direction: column;

        @include mobile {
            margin-bottom: 0px;
        }

        .saveItem {
            display: flex;
            padding: 0px 80px;
            align-items: center;
            justify-content: space-between;

            @include extralarge {
                padding: 60px 50px;
            }

            @include large {
                gap: 60px;
                margin-top: -60px;
                padding: 60px 0px;
                flex-direction: column;
            }


            @include phone {
                padding: 0px 10px;
            }


            .left {
                flex: 1;
                gap: 30px;
                display: flex;
                flex-direction: column;

                @include large {
                    // padding: 0px 100px;
                    padding: 0px 50px;
                }

                @include mobile {
                    padding: 0px 20px;
                }
                @include phone {
                    padding: 0px 10px;
                }
                @include mobile {
                    gap: 20px;
                }

                .top {
                    display: flex;
                    flex-direction: column;

                    @include large {
                        // align-items: center;
                        // align-items: left;
                        // justify-content: flex-start;
                    }

                    h1 {
                        font-size: 40px;
                        letter-spacing: 0.6px;
                        color: $blackHeading;
                        font-family: Futura-Medium;

                        @include large {
                            margin-right: 0px;
                        }

                        @include tablet {
                            font-size: 40px;
                            margin-right: 0px;
                        }

                        @include mobile {
                            font-size: 32px;
                        }
                    }
                }

                span {
                    color: $black2;
                    line-height: 1.4;
                    font-size: 19px;
                    font-weight: 300;
                    margin-right: 120px;
                    letter-spacing: 0.4px;
                    font-family: Futura-Light;

                    @include extralarge {
                        margin-right: 80px;
                    }

                    @include large {
                        margin-right: 0px;
                        text-align: left;
                    }
                    @include phone{
                        font-size: 19.5px;
                    }
                }

                .link {
                    color: inherit;
                    text-decoration: none;
                }

                .saveBtn {
                    gap: 15px;
                    width: 50%;
                    color: $white;
                    display: flex;
                    height: 45px;
                    border: none;
                    cursor: pointer;
                    font-weight: 300;
                    font-size: 16px;
                    align-items: center;
                    border-radius: 5px;
                    letter-spacing: 0.5px;
                    justify-content: center;
                    background-color: $mainGreen;
                    font-family: Futura-Regular;

                    .saveIcon {
                        margin-top: 3px;
                        font-size: 22px;
                    }
                }

            }

            .right {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                @include large{
                    // padding: 0px 100px;
                    padding: 0px 50px;
                }
                @include mobile {
                    padding: 0px 20px;
                }
                @include phone {
                    padding: 0px 10px;
                }

                .imgWrapper {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 10px;

                        @include mobile {
                            width: 100%;
                            height: 100%;
                        }

                    }
                }

            }

        }

    }

}