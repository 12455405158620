@import '../global.scss';

.module-listItem{
    color: gray;
    padding: 10px;
    font-size: 15.5px;
    cursor: pointer;
    border-radius: 5px;
    letter-spacing: 0.5px;
    font-family: Futura-Medium;

    @include phone{
        font-size: 14.5px;
    }
   
    &:hover{
        color: $mainGreen;
        background-color: #A0D6B4;
        
    }
    &.active{
        color: $offWhite;
        background-color: $mainGreen;
    }
}
