@import "../global.scss";

.contact {
    gap: 20px;
    display: flex;
    align-items: center;
    padding: 50px 280px 90px;
    background-color: white;
    background-color: $lightPink;
    min-height: calc(100vh - 90px);

    @include extralarge {
        gap: 10px;
        padding: 50px 150px 90px;
    }
    @include large {
        padding: 50px 80px 90px;
    }
    @include tablet {
        gap: 50px;
        flex-direction: column;
        padding: 50px 50px 90px;
    }
    @include mobile {
        padding: 50px 20px 90px;
    }

    .left {
        flex: 1;
        gap: 30px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include tablet {
            width: 85%;
        }
        @include mobile {
            width: 100%;
        }
        
    
        .itemWrapper {
            gap: 25px;
            display: flex;
            align-items: center;

            .iconWrapper{
                display: flex;
                padding: 25px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                background-color: $mainPink;

                .icon {
                    color: $white;
                    font-size: 35px;
                }
            }

            .contactText {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 10px;

                h3,h4,span {
                    font-size: 15px;
                    @include mobile{
                        font-size: 16px;
                    }
                }

                p {
                    font-size: 15px;
                    color: gray;
                }
            }
        }

        .contactHr {
            display: flex;
            width: 90%;
            border: 0.4px solid rgba(128, 128, 128, 0.4);
        }

    }


    .right {
        flex: 2;
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @include large {
            padding: 0px 40px;
        }

        @include tablet {
            width: 85%;
            padding: 0px 0px;
        }

        @include mobile {
            width: 100%;
        }

        .top {
            gap: 10px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-direction:column;

            p {
                font-size: 13px;
                color: $mainPink;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
            }

            h1 {
                font-size: 30px;
                font-weight: 300;
                color: $darkBlack;
                letter-spacing: 0.5px;

                @include large {
                    font-size: 25px;
                }
                @include mobile {
                    padding: 0px 50px;
                }
                @include phone {
                    font-size: 32px;
                    padding: 0px 30px;
                }
            }

            .contactHr {
                display: none;
                display: flex;
                border: 0.4px solid rgba(128, 128, 128, 0.4);
            }
        }

        form {
            gap: 5px;
            width: 100%;
            display: flex;
            font-size: 16px;
            letter-spacing: 0.5px;
            flex-direction: column;

            .formSec1 {
                gap: 5px;
                width: 100%;
                display: flex;
                @include phone{
                    flex-direction: column;
                }

                .inputWrapper {
                    flex: 1;
                    padding: 16px;
                    font-size: 16px;
                    border: 0.8px solid $gray;
                    font-family: Futura-Regular;
                    @include phone{
                        padding: 16px;
                    }
                }

                .inputWrapper::placeholder {
                    opacity: 0.6;
                    font-size: 15px;
                    padding-left: 10px;
                    letter-spacing: 0.5px;
                    font-family: 'Roboto', sans-serif;
                }

                .inputWrapper:focus {
                    outline: none;
                    background-color: transparent;
                }

            }

            textarea {
                font-size: 16px;
                padding: 18px;
                color: $mainBlack;
                border: 0.8px solid $gray;
            }

            textarea::placeholder {
                opacity: 0.6;
                font-size: 16px;
                padding-left: 10px;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;

            }

            textarea:focus {
                outline: none;
            }

            .contactFormBtn {
                padding: 10px;
                height: 45px;
                font-size: 17px;
                margin-top: 10px;
                cursor: pointer;
                text-decoration: none;
                align-items: center;
                border-radius: 5px;
                justify-content: center;
                color: $white;
                letter-spacing: 0.3px;
                background-color: $mainPink;
                border: 0.5px solid $mainPink;

                @include phone {
                    font-size: 18.5px;
                }

            }

            span {
                font-size: 12px;
                align-self: center;
            }
        }

        
    }

}