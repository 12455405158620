@import '../../global.scss';

.home {
    display: flex;
    flex-direction: column;
    background-color: $white;

    .link {
        color: inherit;
        text-decoration: none;
    }

    .homeHero {
        gap: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        height: calc(100vh - 90px);
        justify-content: center;
        flex-direction: column;

        @include mobile {
            height: 380px;
        }
        @include phone {
            height: 470px;
        }

        .overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);

            @include mobile {
                height: 470px;
            }
            @include phone {
                height: 560px;
            }
        }

        .videoTag {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            gap: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            padding: 0px 260px;
            justify-content: center;
            flex-direction: column;
            position: absolute;

            @include tablet {
                padding: 0px 100px;
            }

            @include mobile {
                padding: 0px 60px;
            }

            @include phone {
                padding: 0px 40px;
            }

            h2 {
                font-size: 48px;
                color: $offWhite;
                font-family: Futura-Bold;

                @include tablet {
                    font-size: 42px;
                }

                @include mobile {
                    font-size: 38px;
                }

                @include phone {
                    text-align: left;
                    font-size: 38px;
                }
            }

        }
    }

    //Section 2
    .secWrapper {
        gap: 30px;
        display: flex;
        padding: 80px 100px;
        flex-direction: column;

        @include extralarge{
            padding: 80px 50px 50px;
        }
        @include tablet{
            padding: 80px 30px 50px;
        }
        @include mobile{
            padding: 50px 20px 40px;
        }

        .top {
            gap: 20px;
            display: flex;
            flex-direction: column;

            .leftTop {
                display: flex;
                flex-direction: column;

                p {
                    font-size: 13px;
                    color: $mainPink;
                    font-weight: 400;
                    letter-spacing: 1.3px;
                    font-family: 'Roboto', sans-serif;

                    @include phone {
                        font-size: 15px;
                    }
                }
            }

            .leftBottom {
                gap: 10px;
                display: flex;

                h3 {
                    font-size: 35px;
                    font-weight: 300;
                    color: $mainGreen;

                    @include mobile {
                        font-size: 29px;
                    }
                    @include phone {
                        font-size: 28px;
                    }
                }

            }
        }

        .bottom {
            gap: 20px;
            display: flex;
            // padding: 0px 100px 0px 200px;
            padding: 0px 50px 0px 100px;
            flex-direction: column;

            @include extralarge{
                padding: 0px 50px;
            }
            @include tablet{
                padding: 0px 0px;
            }

            span {
                color: $black2;
                line-height: 1.4;
                font-size: 21.5px;
                font-weight: 300;
                letter-spacing: 0.5px;

                @include mobile{
                    font-size: 21px;
                }
            }

        }

    }

    ///PROGRAMS///
    .thirdWrapper {
        gap: 40px;
        display: flex;
        flex-direction: column;
        padding: 50px 100px 100px 100px;

        @include extralarge{
            padding: 50px 50px 100px 50px;
        }
        @include tablet{
            padding: 50px 30px 100px 30px;
        }
        @include mobile{
            padding: 50px 20px 100px 20px;
        }

        .top {
            display: flex;
            margin-bottom: 20px;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            flex-direction: column;

            @include mobile{
                margin-bottom: 0px;
            }

            p {
                font-size: 13px;
                color: $mainPink;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
                @include phone {
                    font-size: 15px;
                }
            }
            h3 {
                font-size: 35px;
                margin-top: 10px;
                // font-weight: 300;
                color: $subHeading;
                letter-spacing: 0.5px;
                // font-family: Futura-Medium;

                @include mobile {
                    font-size: 30px;
                }

                @include phone {
                    font-size: 34px;
                }
            }

        }

        .bottom {
            gap: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include large{
                gap: 40px;
                grid-template-columns: repeat(2, 1fr);
            }
            @include tablet{
                padding: 0px 50px;
                grid-template-columns: repeat(1, 1fr);
            }
            @include mobile{
                padding: 0px 0px;
            }

            .item {
                flex: 1;
                width: 100%;
                border: none;
                align-items: center;
                background-color: $white;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                .imgWrapper {
                    height: 300px;
                    width: 100%;

                    @include tablet{
                        // height: 400px;
                    }

                    img {
                        flex: 1;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                    }
                }

                .text {
                    gap: 10px;
                    display: flex;
                    color: $black;
                    padding: 15px 20px;
                    flex-direction: column;
                    background-color: $lightPink;

                    h4 {
                        font-size: 20px;
                        // font-size: 16px;
                        font-weight: 300;
                        letter-spacing: 0.6px;
                        color: $blackHeading;
                        @include phone{
                            font-size: 24px;
                        }
                    }
                    span {
                        font-size: 16px;
                        font-weight: 300;
                        color: $darkBlack;
                        line-height: 1.3;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                        @include mobile {
                            font-size: 17px;
                        }
                        @include phone {
                            font-size: 19px;
                        }
                    }

                    .textWrapper {
                        gap: 5px;
                        display: flex;
                        color: $white;
                        color: $mainPink;
                        align-items: center;

                        p {
                            font-size: 16px;
                            font-weight: 300;
                            letter-spacing: 0.5px;
                            font-family: Futura-Light;
                            @include phone {
                                font-size: 18.5px;
                            }
                        }

                        .rightIcon {
                            font-size: 15px;
                            margin-top: 3px;
                        }
                    }

                }

            }
        }
    }

    ////////// NUMBERS //////////
    .fourthWrapper {
        gap: 40px;
        display: flex;
        flex-direction: column;
        padding: 50px 100px;
        background-color: $mainGreen;


        @include large {
            padding: 50px 50px;
        }

        @include tablet {
            padding: 50px 20px;
        }


        .top {
            display: flex;
            margin-bottom: 20px;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            flex-direction: column;

            @include mobile{
                margin-bottom: 0px;
            }

            p {
                font-size: 13px;
                color: $white;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
                @include phone {
                    font-size: 15px;
                }
            }
            h3 {
                font-size: 35px;
                margin-top: 10px;
                color: $white;
                font-weight: bold;
                letter-spacing: 0.5px;
                // font-family: Futura-Medium;

                @include mobile {
                    font-size: 30px;
                }

                @include phone {
                    font-size: 34px;
                }
            }


        }


        .bottom {
            display: flex;
            padding: 0px 100px;
            flex-direction: column;

            @include large {
                padding: 0px 50px ;
            }

            @include tablet {
                padding: 0px 20px;
            }

            .numbersTop {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0.4px solid lightgray;

                @include mobile{}

                .item1 {
                    flex: 1;
                    gap: 10px;
                    display: flex;
                    padding: 50px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border-right: 0.4px solid lightgray;

                    @include mobile{
                        padding: 50px 0px;
                    }

                    .wrapNumber {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        h1 {
                            font-size: 40px;
                            color: $white;
                            @include mobile{
                                font-size: 30px;
                            }
                            @include phone{
                                font-size: 35px;
                            }
                        }

                        .numIcon {
                            font-size: 20px;
                            color: $mainGreen;
                            color: $white;
                            @include mobile{
                                font-size: 15px;
                            }
                        }
                    }


                    p {
                        color: $white;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;
                        @include mobile{
                            display: none;
                        }
                    }
                    .responsive{
                        display: none;
                        @include mobile{
                            gap: 3px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }

                        span {
                            color: $white;
                            font-weight: 400;
                            letter-spacing: 1.5px;
                            font-size: 12px;
                            font-family: 'Roboto', sans-serif;

                            @include phone{
                                font-size: 13px;
                            }

                        }
                    }

                }

                .item2 {
                    flex: 1;
                    gap: 10px;
                    display: flex;
                    padding: 50px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    @include mobile{
                        padding: 50px 0px;
                    }

                    .wrapNumber {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        h1 {
                            font-size: 40px;
                            color: $white;
                            @include mobile{
                                font-size: 30px;
                            }
                            @include phone{
                                font-size: 35px;
                            }
                        }

                        .numIcon {
                            font-size: 20px;
                            color: $white;
                            @include mobile{
                                font-size: 15px;
                            }
                        }
                    }

                    p {
                        color: $white;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;
                        @include mobile{
                            display: none;
                        }
                    }
                    .responsive{
                        display: none;
                        @include mobile{
                            gap: 3px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }

                        span {
                            color: $white;
                            font-weight: 400;
                            letter-spacing: 1.5px;
                            font-size: 12px;
                            font-family: 'Roboto', sans-serif;

                            @include phone{
                                font-size: 13px;
                            }


                        }
                    }
                }


            }

            .numbersBottom {
                flex: 1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item1 {
                    flex: 1;
                    gap: 10px;
                    display: flex;
                    padding: 50px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border-right: 0.4px solid lightgray;

                    @include mobile{
                        padding: 50px 0px;
                    }

                    .wrapNumber {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        h1 {
                            font-size: 40px;
                            color: $mainGreen;
                            color: $white;
                            @include mobile{
                                font-size: 30px;
                            }
                            @include phone{
                                font-size: 35px;
                            }
                        }

                        .numIcon {
                            font-size: 20px;
                            color: $mainGreen;
                            color: $white;
                            @include mobile{
                                font-size: 15px;
                            }
                        }
                    }

                    p {
                        color: $white;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;

                        @include mobile{
                            display: none;
                        }
                    }
                    .responsive{
                        display: none;
                        @include mobile{
                            gap: 3px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }

                        span {
                            color: $white;
                            font-weight: 400;
                            letter-spacing: 1.5px;
                            font-size: 12px;
                            font-family: 'Roboto', sans-serif;
                            @include phone{
                                font-size: 13px;
                            }


                        }
                    }
                }

                .item2 {
                    flex: 1;
                    gap: 10px;
                    display: flex;
                    padding: 50px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    @include mobile{
                        padding: 50px 0px;
                    }

                    .wrapNumber {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        h1 {
                            font-size: 40px;
                            color: $mainGreen;
                            color: $white;

                            @include mobile{
                                font-size: 30px;
                            }
                            @include phone{
                                font-size: 35px;
                            }
                        }

                        .numIcon {
                            font-size: 20px;
                            color: $mainGreen;
                            color: $white;
                            @include mobile{
                                font-size: 15px;
                            }
                        }
                    }

                    p {
                        color: $white;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;

                        @include mobile{
                            display: none;
                        }
                    }

                    .responsive{
                        display: none;
                        @include mobile{
                            gap: 3px;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }

                        span {
                            color: $white;
                            font-weight: 400;
                            letter-spacing: 1.5px;
                            font-size: 12px;
                            font-family: 'Roboto', sans-serif;

                            @include phone{
                                font-size: 13px;
                            }


                        }
                    }

                }

            }
        }

    }

    //////////PARTNERS//////////
    .fifthWrapper {
        gap: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 120px 150px 120px 130px;

        @include large {
            padding: 100px 50px 100px 50px;
        }

        @include tablet {
            padding: 100px 20px 80px 20px;
        }


        .top {
            display: flex;
            margin-bottom: 20px;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            flex-direction: column;

            @include mobile{
                margin-bottom: 0px;
            }

            p {
                font-size: 13px;
                color: $mainPink;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
            }
            h3 {
                font-size: 35px;
                margin-top: 15px;
                font-weight: bold;
                color: $subHeading;
                letter-spacing: 0.5px;
                // font-family: Futura-Medium;

                @include mobile {
                    font-size: 30px;
                }

                @include phone {
                    font-size: 34px;
                    letter-spacing: 0.1px;
                }
            }

        }

        .bottom {
            gap: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include phone {
                gap: 20px;
            }


            .bottomItem {
                gap: 35px;
                width: 100%;
                display: grid;
                align-items: center;
                justify-content: center;
                grid-template-columns: repeat(6, 1fr);

                padding: 0px 210px;

                @include extralarge {
                    grid-template-columns: repeat(5, 1fr);
                }

                @include large {
                    padding: 0px 50px;
                    grid-template-columns: repeat(4, 1fr);
                }

                @include tablet {
                    gap: 15px;
                    padding: 0px 80px;
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mobile {
                    padding: 0px 0px;
                    grid-template-columns: repeat(3, 1fr);
                }

                @include phone {
                    padding: 0px 0px;
                    flex-direction: column;
                    grid-template-columns: repeat(2, 1fr);
                }

                .item {
                    flex: 1;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    border-radius: 10px;
                    background-color: $white;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    img {
                        flex: 1;
                        width: 150px;
                        object-fit: cover;
                        border-radius: 10px;

                        @include mobile {
                            width: 140px;
                        }

                        @include phone {
                            width: 180px;
                        }

                    }
                }

            }

        }

    }

    //////Newsletter
    .subscribe{
        gap: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: $white;

        padding: 0px 150px 120px 150px;

        @include large {
            padding: 30px 50px 80px 50px;
        }

        @include tablet {
            padding: 30px 20px 80px 20px;
        }

        .left{
            gap: 15px;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;

            p {
                font-size: 13px;
                color: $mainPink;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
                @include phone {
                    font-size: 15px;
                }
            }
            h3 {
                font-size: 35px;
                color: $subHeading;
                font-weight: bold;
                letter-spacing: 0.5px;
                // font-family: Futura-Medium;

                @include mobile {
                    font-size: 30px;
                }
                @include phone {
                    font-size: 35px;
                    letter-spacing: 0.1px;
                }
            }
            .text1{
                gap: 3px;
                display: flex;
                flex-direction: column;

                @include phone {
                    display: none;
                }

                span {
                    color: $black;
                    line-height: 1.3;
                    font-size: 17.5px;
                    font-weight: 300;
                    letter-spacing: 0.3px;
                    font-family: Futura-Light;

                   @include mobile {
                        font-size: 16.5px;
                    }

                }

            }
            .text2{
                display: none;

                @include phone {
                    gap: 3px;
                    display: flex;
                    flex-direction: column;

                    span {
                        color: $black;
                        line-height: 1.3;
                        font-size: 20px;
                        font-weight: 300;
                        letter-spacing: 0.3px;
                        font-family: Futura-Light;

                    }
                }

            }

        }

        form{
            gap: 10px;
            width: 80%;
            display: flex;
            padding: 0px 100px;

            @include extralarge{
                padding: 0px 0px;
            }
            @include mobile{
                width: 100%;
            }

            .item{
                gap: 5px;
                flex: 2;
                width: 100%;
                display: flex;
                flex-direction: column;

                input {
                    width: 100%;
                    font-size: 15px;
                    color: $black;
                    padding: 13px 20px;
                    border-radius: 3px;
                    border: 0.4px solid $mainPink;

                    @include phone {
                        padding: 18px 15px;
                    }
                }

                input::placeholder {
                    font-size: 13.5px;
                    letter-spacing: 0.3px;
                    font-family: 'Roboto', sans-serif;

                    @include phone {
                        font-size: 15px;
                    }
                }

                input:focus {
                    outline: none;
                }

                .formItem{
                    gap: 5px;
                    display: flex;
                    @include phone{
                        flex-direction: column;
                    }
                }
            }

            button {
                gap: 10px;
                width: 100%;
                display: flex;
                font-size: 16px;
                padding: 13px 80px;
                border-radius: 3px;
                align-items: center;
                justify-content: center;
                background-color: $mainPink;
                border: 0.4px solid $mainPink;

                p {
                    color: $white;
                    font-size: 15px;
                    letter-spacing: 0.5px;
                    font-family: 'Roboto', sans-serif;
                    @include phone {
                        font-size: 19px;
                    }
                }

                .aboutIcon {
                    color: $white;
                    font-size: 15px;
                    margin-top: 2px;
                }

            }
        }

    }


}