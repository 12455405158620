@import '../../global.scss';

///////ABOUT//////////
.about {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .aboutHero {
        width: 100%;
        height: 400px;
        display: flex;
        position: relative;
        margin-bottom: 20px;

        .aboutImg1 {
            width: 100%;
            display: flex;
            object-fit: cover;

            @include large {
                display: none;
            }
        }

        .aboutImg2 {
            display: none;

            @include large {
                width: 100%;
                display: flex;
                object-fit: cover;
            }

            @include phone {
                display: none;
            }
        }

        .aboutImg3 {
            display: none;

            @include phone {
                width: 100%;
                display: flex;
                object-fit: cover;
            }
        }

        .top {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.38);

            h2 {
                font-size: 50px;
                color: $white;
                letter-spacing: 0.5px;
                font-family: Futura-Bold;

                @include mobile {
                    font-size: 45px;
                }
            }
        }
    }

    .aboutWhy {
        gap: 80px;
        display: flex;
        padding: 60px 100px;
        margin-bottom: 60px;

        @include extralarge {
            gap: 50px;
            padding: 60px 50px 20px;
        }

        @include large {
            padding: 40px 50px 50px;
            flex-direction: column-reverse;
        }

        @include mobile {
            padding: 40px 20px 30px;
        }

        @include phone {
            padding: 40px 20px 0px;
        }

        .left {
            flex: 1.2;
            gap: 20px;
            display: flex;
            border-radius: 10px;
            padding: 30px 30px 40px;
            flex-direction: column;
            background-color: $lightPink;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

            @include large {
                padding: 30px 30px 60px;
            }

            @include phone {
                padding: 30px 20px 60px;
            }

            h4 {
                font-size: 21px;
                color: $mainPink;
                font-weight: 300;
                letter-spacing: 0.8px;
                font-family: Futura-Light;

                @include phone {
                    font-size: 24px;
                }
            }

            span {
                font-size: 17px;
                font-weight: 300;
                color: $blackHeading;
                line-height: 1.5;
                font-family: Futura-Light;

                @include phone {
                    font-size: 18.5px;
                }
            }

            form {
                gap: 10px;
                display: flex;
                flex-direction: column;

                @include phone {
                    gap: 20px;
                }

                input {
                    flex: 1;
                    width: 100%;
                    font-size: 15px;
                    padding: 13px 20px;
                    border-radius: 3px;
                    border: 0.4px solid $lightGray2;

                    @include phone {
                        padding: 18px 15px;
                    }
                }

                input::placeholder {
                    font-size: 14px;
                    letter-spacing: 0.4px;
                    font-family: 'Roboto', sans-serif;

                    @include phone {
                        font-size: 15.5px;
                    }
                }

                input:focus {
                    outline: none;
                }

                button {
                    gap: 10px;
                    width: 100%;
                    display: flex;
                    font-size: 15px;
                    cursor: pointer;
                    padding: 13px 20px;
                    border-radius: 3px;
                    align-items: center;
                    justify-content: center;
                    background-color: $mainPink;
                    border: 0.4px solid $lightGray2;

                    p {
                        color: $white;
                        font-size: 15.5px;
                        font-family: Futura-Medium;

                        @include phone {
                            font-size: 17.5px;
                        }
                    }

                    .aboutIcon {
                        font-size: 18px;
                        color: $white;
                        margin-top: 2px;
                    }


                }

            }

        }

        .right {
            flex: 2;
            gap: 20px;
            display: flex;
            flex-direction: column;
            padding: 0px 30px 30px 0px;

            p {
                font-size: 13px;
                color: $mainPink;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;
                @include phone {
                    font-size: 15px;
                }
            }

            h3 {
                font-weight: 400;
                font-size: 30px;
                line-height: 1.2;
                letter-spacing: 0.8px;
                color: $subHeading;

                @include mobile {
                    font-size: 26px;
                }

                @include phone {
                    font-size: 29px;
                }
            }

            span {

                color: $black;
                line-height: 1.3;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0.3px;
                font-family: Futura-Light;

                @include phone {
                    font-size: 21px;
                }
            }
        }

    }

    .aboutMain {
        gap: 20px;
        display: grid;
        padding: 60px 100px;
        grid-template-columns: repeat(3, 1fr);

        @include extralarge {
            padding: 60px 50px;
        }

        @include large {
            gap: 30px;
            padding: 60px 50px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            padding: 50px 50px;
            grid-template-columns: repeat(1, 1fr);
        }

        @include mobile {
            padding: 30px 20px;
        }
    


        .left {
            gap: 28px;
            display: flex;
            cursor: pointer;
            font-weight: 300;
            padding: 50px 50px;
            align-items: center;
            border-radius: 10px;
            flex-direction: column;
            background-color: $lightPink;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

            @include extralarge {
                padding: 50px 10px;
            }

            @include tablet {
                padding: 50px 60px;
            }

            @include phone {
                padding: 50px 30px;
            }

            h3 {
                font-weight: 400;
                font-size: 28px;
                line-height: 1.2;
                letter-spacing: 0.8px;
                color: $darkBlack;
                // font-family: Futura-Medium;
            }


            span {
                line-height: 1.5;
                text-align: center;
                font-size: 18.5px;
                letter-spacing: 0.4px;
                color: $darkBlack;
                font-family: Futura-Light;

                @include phone {
                    font-size: 20px;
                }
            }
        }

        .center {
            gap: 25px;
            display: flex;
            cursor: pointer;
            font-weight: 300;
            color: $darkBlack;
            padding: 50px 40px;
            align-items: center;
            border-radius: 10px;
            flex-direction: column;
            justify-content: center;
            background-color: $lightPink;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

            @include extralarge {
                padding: 50px 10px;
            }

            @include tablet {
                padding: 50px 60px;
            }

            @include phone {
                padding: 50px 30px;
            }


            h3 {
                font-weight: 400;
                font-size: 28px;
                line-height: 1.2;
                letter-spacing: 0.8px;
                color: $blackHeading;
                // font-family: Futura-Medium;
            }


            span {
                line-height: 1.5;
                text-align: center;
                color: $black;
                font-size: 18.5px;
                letter-spacing: 0.4px;
                font-family: Futura-Light;

                @include phone {
                    font-size: 20px;
                }
            }
        }


        .right {
            gap: 15px;
            display: flex;
            cursor: pointer;
            font-weight: 300;
            color: $darkBlack;
            border-radius: 10px;
            align-items: center;
            padding: 50px 50px;
            flex-direction: column;
            background-color: $lightPink;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

            @include extralarge {
                padding: 50px 10px;
            }

            h3 {
                font-weight: 400;
                font-size: 28px;
                line-height: 1.2;
                letter-spacing: 0.8px;
                color: $blackHeading;
                // font-family: Futura-Medium;

            }

            ul {
                gap: 10px;
                display: flex;
                list-style: none;
                flex-direction: column;

                span {
                    line-height: 1.5;
                    text-align: center;
                    color: $darkBlack;
                    font-family: 'Roboto', sans-serif;
                }

                li {
                    gap: 10px;
                    display: flex;
                    // font-size: 16px;
                    align-items: center;

                    span {
                        font-size: 18.5px;
                        color: $darkBlack;
                        font-family: Futura-Light;

                        @include phone {
                            font-size: 20px;
                        }
                    }

                    .valueIcon {
                        font-size: 15px;
                        color: $mainPink;
                    }
                }
            }
        }
    }

    .aboutObject {
        gap: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 70px 100px;
        margin-bottom: 60px;
        background-color: $lightPink;

        @include extralarge {
            padding: 50px 50px;
        }

        @include large {
            gap: 20px;
            padding: 80px 50px 50px;
            flex-direction: column;
        }

        @include mobile {
            padding: 60px 20px 40px;
        }

        .objectLeft {
            flex: 1.5;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 520px;
                height: 400px;
                border: none;
                border-radius: 10px;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                @include large {
                    width: 100%;
                    height: 490px;
                    object-fit: cover;
                }

                @include mobile {
                    height: 350px;
                }

            }


        }

        .objects {
            flex: 1;
            width: 100%;
            height: 100%;
            padding: 40px 20px;
            align-items: flex-start;

            @include tablet {
                padding: 40px 0px;
            }

            @include mobile {
                padding: 40px 10px 40px 0px;
            }

            @include mobile {
                padding: 40px 20px 40px 0px;
            }

            .objectWrapper {
                gap: 25px;
                width: 100%;
                height: 100%;
                display: flex;
                object-fit: cover;
                flex-direction: column;

                p {
                    font-size: 13px;
                    color: $mainPink;
                    font-weight: 400;
                    letter-spacing: 1.3px;
                    font-family: 'Roboto', sans-serif;
                    @include phone {
                        font-size: 15px;
                    }
                }


                h3 {
                    font-size: 32px;
                    font-weight: 400;
                    margin-top: -12px;
                    color: $blackHeading;
                    letter-spacing: 0.5px;

                    @include mobile {
                        font-size: 26px;
                    }

                    @include phone {
                        font-size: 29px;
                    }
                }

                ul {
                    gap: 25px;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    list-style: none;
                    flex-direction: column;


                    li {
                        gap: 5px;
                        display: flex;
                        flex-direction: column;

                        .heading {
                            gap: 15px;
                            display: flex;
                            align-items: flex-start;

                            span {
                                line-height: 1.5;
                                color: $black;
                                font-size: 18.5px;
                                font-family: Futura-Medium;

                                @include phone {
                                    font-size: 19px;
                                }
                            }

                            .valueIcon {
                                font-size: 25px;
                                color: $mainGreen;
                                color: $mainPink;
                            }
                        }

                        p {
                            line-height: 1.5;
                            font-size: 17px;
                            margin-left: 40px;
                            font-weight: 300;
                            letter-spacing: 0.5px;
                            color: $black2;
                            font-family: Futura-Light;

                            @include phone {
                                font-size: 18.5px;
                            }

                        }

                    }
                }

            }
        }
    }

}


///////TEAM//////////
.team {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .teamHero {
        flex: 1;
        width: 100%;
        display: flex;
        position: relative;

        .team1 {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(50%);

            @include large {
                display: none;
            }
        }

        .team2 {
            display: none;

            @include large {
                display: flex;
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: brightness(50%);
            }
        }

        .top {
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            position: absolute;
            justify-content: center;
            flex-direction: column;
            background-color: rgba($color: #000000, $alpha: 0.45);

            h2 {
                font-size: 50px;
                color: $white;
                letter-spacing: 0.5px;
                font-family: Futura-Bold;

                @include mobile {
                    font-size: 45px;
                }
            }

            p {
                font-size: 13px;
                color: $white;
                font-weight: 400;
                letter-spacing: 1.3px;
                font-family: 'Roboto', sans-serif;

                @include phone {
                    font-size: 15px;
                }
            }

            span {
                color: $white;
                font-weight: 400;
                font-size: 17.5px;
                line-height: 1.2;
                text-align: center;
                letter-spacing: 0.8px;
                font-family: Futura-Light;
                padding: 0px 380px;

                @include extralarge {
                    padding: 0px 200px;
                }

                @include large {
                    padding: 20px 100px;
                }

                @include tablet {
                    padding: 20px 50px;
                }

                @include mobile {
                    padding: 20px 20px;
                }
            }

        }

    }

    .teamWrapper {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 320px;
        margin-bottom: 100px;
        justify-content: center;
        flex-direction: column;

        @include extralarge {
            padding: 50px 150px;
        }

        @include large {
            padding: 50px 50px;
        }

        @include tablet {
            padding: 50px 20px;
        }

        @include mobile {
            padding: 0px 0px;
        }

        .teamItem {
            gap: 30px;
            display: flex;
            padding: 40px;
            border-radius: 20px;
            flex-direction: column;
            background-color: $lightPink;

            @include mobile {
                width: 100%;
                padding: 20px;
                position: unset;
                margin-top: 0px;
                border-radius: 0px;
            }

            .top {
                gap: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include tablet{
                    gap: 40px;
                }
                @include mobile{
                    gap: 20px;
                }

                img {
                    // width: 180px;
                    // height: 180px;
                    width: 220px;
                    height: 220px;
                    border-radius: 20px;

                    @include mobile {
                        width: 120px;
                        height: 120px;
                    }

                }

                .divider2 {
                    width: 2px;
                    height: 80px;
                    display: inline-block;
                    background-color: $gray;

                    @include tablet {
                        // display: none;
                    }

                    @include phone {
                        // display: none;
                    }
                }

                .text {
                    gap: 5px;
                    display: flex;
                    padding: 10px 10px 15px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    h3 {
                        font-weight: 300;
                        font-size: 28px;
                        color: $darkBlack;
                        letter-spacing: 0.5px;

                        @include large {
                            font-size: 25px;
                        }

                        @include tablet {
                            font-size: 22px;
                        }
                    }

                    span {
                        color: $subHeading;
                        font-size: 12px;
                        font-weight: 300;
                        text-align: center;
                        letter-spacing: 0.8px;
                        font-family: Futura-Light;

                        @include large {
                            // font-size: 16.5px;
                        }

                        @include mobile {
                            // font-family: Futura-Medium;
                        }
                    }
                }

            }

            .bottom {
                gap: 10px;
                display: flex;
                flex-direction: column;

                span {
                    color: $black;
                    font-size: 16.5px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;
                    @include phone{
                        font-size: 17.5px;
                    }
                }

                .read-more-link {
                    color: $mainPink;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 300;
                    display: flex;
                    align-self: flex-end;
                    margin-top: 10px;
                    align-items: center;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;
                    letter-spacing: 1px;
                    text-decoration: underline;

                    p {
                        color: $black;
                        font-weight: 300;
                        margin-bottom: 2px;
                        font-family: Futura-Light;
                    }
                }

                .extra-content {
                    display: flex;
                    color: $black;
                    font-size: 16.5px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.5px;
                    flex-direction: column;
                    font-family: Futura-Light;

                    .read-more-link {
                        color: $mainPink;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 300;
                        display: flex;
                        align-self: flex-end;
                        margin-top: 15px;
                        align-items: center;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                        letter-spacing: 1px;
                        text-decoration: underline;
                    }

                }
            }
        }
    }

    .leadershipHeading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 100px;
        justify-content: center;

        h2 {
            font-size: 30px;
            line-height: 1.2;
            text-align: center;
            font-weight: 500;
            letter-spacing: 0.8px;
            color: $blackHeading;
        }

    }

    .leadership {
        gap: 15px;
        width: 100%;
        display: grid;
        padding: 30px 100px;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);

        @include extralarge {
            grid-template-columns: repeat(3, 1fr);
        }

        @include large {
            padding: 0px 50px;
            grid-template-columns: repeat(3, 1fr);
        }

        @include tablet {
            gap: 35px;
            padding: 0px 50px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
            padding: 20px;
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: $lightPink;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            img {
                flex: 1;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
            }

            .text {
                gap: 5px;
                display: flex;
                padding: 10px 10px 15px;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 19px;
                    color: $darkBlack;
                    letter-spacing: 0.5px;
                    font-family: Futura-Medium;

                    @include large {
                        font-size: 16px;
                    }

                    @include tablet {
                        font-size: 19px;
                    }
                }

                span {
                    color: $black;
                    font-size: 16.6px;
                    font-weight: 300;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;

                    @include large {
                        // font-size: 16.5px;
                    }

                    @include tablet {
                        font-size: 17px;
                    }
                }
            }
        }

    }

    .trainerHeading {
        width: 100%;
        display: flex;
        margin-top: 30px;
        align-items: center;
        padding: 10px 100px;
        justify-content: center;

        h2 {
            color: $blackHeading;
            font-size: 30px;
            line-height: 1.2;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0.8px;
        }

    }

    .leadTrainers {
        gap: 15px;
        width: 100%;
        display: grid;
        margin-bottom: 40px;
        align-items: center;
        padding: 30px 150px 80px;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);

        @include extralarge {
            grid-template-columns: repeat(3, 1fr);
        }

        @include large {
            padding: 30px 50px;
            grid-template-columns: repeat(3, 1fr);
        }

        @include tablet {
            gap: 35px;
            padding: 30px 50px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
            padding: 30px 20px;
            grid-template-columns: repeat(1, 1fr);
        }


        .item {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: $lightPink;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            img {
                flex: 1;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            .text {
                gap: 5px;
                display: flex;
                padding: 10px 0px;
                align-items: center;
                flex-direction: column;

                h3 {
                    font-size: 19px;
                    color: $darkBlack;
                    letter-spacing: 0.5px;
                    font-family: Futura-Medium;

                    @include large {
                        font-size: 16px;
                    }

                    @include tablet {
                        font-size: 19px;
                    }
                }

                span {
                    color: $black;
                    font-size: 16.6px;
                    font-weight: 300;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;

                    @include large {
                        // font-size: 14px;
                    }

                    @include tablet {
                        font-size: 17px;
                    }
                }

            }
        }

    }

}


///////IMPACT STORIES//////////
.impact {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .impactHero {
        width: 100%;
        height: 400px;
        height: 380px;
        display: flex;
        position: relative;
        margin-bottom: 50px;

        img {
            width: 100%;
            object-fit: cover;
        }

        .top {
            gap: 5px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.48);

            h2 {
                font-size: 50px;
                color: $white;
                letter-spacing: 0.8px;
                font-family: Futura-Medium;

                @include mobile {
                    font-size: 45px;
                }
            }

            p {
                font-size: 20px;
                color: $white;
                font-weight: 300;
                letter-spacing: 0.1px;

            }
        }

    }

    .itemWrapper {
        gap: 40px;
        display: flex;
        padding: 0px 80px 60px;
        flex-direction: column;
        margin-top: -30px;
        background-color: $white;

        @include extralarge {
            padding: 0px 50px 60px;
        }

        @include tablet {
            padding: 0px 20px 60px;
        }

        .titles {
            gap: 3px;
            display: flex;
            flex-direction: column;

            .item {
                gap: 10px;
                display: flex;

                span {
                    color: $black;
                    font-size: 15px;
                    font-weight: 300;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;
                }

                h4 {
                    font-size: 15px;
                    font-weight: 400;
                    letter-spacing: 0.6px;
                    font-family: Futura-Medium;
                }
            }
        }

        .impactObject {
            width: 100%;
            height: 490px;
            display: flex;
            margin-top: -20px;
            margin-bottom: 100px;

            @include large {
                height: 100%;
                flex-direction: column-reverse;
            }

            .imgWrapper {
                flex: 1.5;
                width: 100%;
                height: 100%;

                .videoTag {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .objects {
                flex: 1;
                width: 100%;
                height: 100%;
                padding: 40px 20px;
                align-items: flex-start;
                background-color: $lightPink;

                .wrapper {
                    gap: 25px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    object-fit: cover;
                    flex-direction: column;

                    h3 {
                        font-size: 20px;
                        letter-spacing: 0.5px;
                        color: $mainPink;
                        font-family: Futura-Medium;
                    }

                    span {
                        color: $black;
                        font-size: 16.5px;
                        font-weight: 300;
                        line-height: 1.5;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;

                        @include phone{
                            font-size: 17.5px;
                        }
                    }

                }

            }
        }



    }
}