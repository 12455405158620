@import '../../global.scss';

//FEATURED SHEBOSS
.featuredImgaes{
    gap: 13px;
    display: flex;
    padding: 10px 0px 100px;
    flex-direction: column;

    @include large{
        padding: 10px 20px 50px;
    }
    @include tablet{
        padding: 20px 10px 40px;
    }

    @include phone{
        margin-top: 20px;
    }

    .carousel {
        // gap: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .carouselItems{
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet{
            padding: 0px 5px;
        }

        .imageCard {
            width: 100%;
            height: 100%;
            display: flex;

            .image {
                width: 100%;
                height: 100%;

                .mainImg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;

                }

            }
        }
    }

}