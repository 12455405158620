@import '../../global.scss';

//////// VOLUNTEER ///////////
.volunteer {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .involveHero {
        width: 100%;
        height: 400px;
        display: flex;
        position: relative;
        margin-bottom: 80px;
        @include mobile{
            height: 300px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }


        .top {
            gap: 5px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.45);

            h2 {
                font-size: 50px;
                color: $white;
                letter-spacing: 0.5px;
                font-family: Futura-Medium;
                @include mobile{
                    font-size: 45px;
                }
            }

        }

    }

    .itemWrapper {
        gap: 60px;
        display: flex;
        margin-bottom: 100px;
        flex-direction: column;

        @include large {
            margin-top: -60px;
        }

        .item {
            display: flex;
            padding: 0px 80px;

            @include extralarge{
                padding: 0px 30px;
            }
            @include large {
                padding: 0px 50px;
                flex-direction: column-reverse;
            }
            @include tablet {
                padding: 0px 30px;
            }
            @include mobile {
                padding: 0px 20px;
            }


            .left {
                flex: 1.2;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    // width: 500px;
                    // height: 380px;
                    width: 100%;
                    border: none;
                    border-radius: 20px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    
                    @include extralarge {
                        // height: 340px;
                    }
                    @include large {
                        width: 100%;
                        height: 450px;
                        object-fit: cover;
                    }
                    @include tablet {
                        height: 400px;
                    }
                    @include mobile {
                        height: 300px;
                    }
    
                }

            }

            .right {
                flex: 1;
                gap: 10px;
                display: flex;
                padding: 40px;
                flex-direction: column;
                justify-content: flex-start;
    
                @include extralarge{
                    gap: 10px;
                }
                @include large {
                    padding: 20px 40px 40px 0px;
                }
                @include mobile {
                    gap: 10px;
                    padding: 0px 40px 40px 0px;
                }

                .titles{
                    font-size: 13px;
                    color: $mainPink;
                    font-weight: 400;
                    letter-spacing: 1.3px;
                    font-family: 'Roboto', sans-serif;
                }    

                h3 {
                    font-size: 25px;
                    font-weight: 300;
                    letter-spacing: 0.6px;
                    color: $blackHeading;
        
                    @include large {
                        margin-right: 0px;
                        margin-bottom: 10px;
                    }
                    
                    @include mobile {
                        font-size: 24px;
                    }
                }

                span {
                    color: $mainBlack;
                    font-size: 17px;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;

                    @include large {
                        margin-right: 80px;
                    }
                    @include tablet{
                        margin-right: 20px;
                    }
                    @include mobile {
                        margin-right: 0px;
                    }
                    @include phone {
                        font-size: 18.5px;
                    }
                }

                .link {
                    color: inherit;
                    text-decoration: none;
                }

                .apply {
                    gap: 15px;
                    display: flex;
                    height: 45px;
                    width: 50%;
                    border: none;
                    cursor: pointer;
                    margin-top: 20px;
                    color: $white;
                    align-items: center;
                    border-radius: 5px;
                    justify-content: center;
                    background-color: $mainGreen;
                    @include mobile{
                        margin-top: 10px;
                    }
                    @include phone {
                        width: 70%;
                    }

                    p {
                        font-size: 15px;
                        font-weight: 300;
                        letter-spacing: 0.6px;
                        font-family: 'Roboto', sans-serif;
                        @include mobile {
                            font-size: 14.5px;
                        }
                        @include phone {
                            font-size: 15.5px;
                        }
                        &:hover{
                            font-weight: 400;
                        }
                    }

                    .applyIcon {
                        margin-top: 3px;
                        font-size: 22px;
                    }
                    &:hover {
                        color: $mainPink;
                        font-weight: 400;
                        border: 0.4px solid $mainPink;
                        background-color: $lightPink;
                    }

                }

            }
        }

        .item2 {
            display: flex;
            padding: 0px 80px;

            @include extralarge{
                padding: 0px 30px;
            }
            @include large {
                padding: 0px 50px;
                flex-direction: column;
            }
            @include tablet {
                padding: 0px 30px;
            }
            @include mobile {
                padding: 0px 20px;
            }

            .left {
                flex: 1;
                gap: 10px;
                display: flex;
                padding: 40px;
                flex-direction: column;
                // background-color: $gray;

                @include extralarge{
                    gap: 10px;
                }
                @include large {
                    padding: 20px 40px 40px 0px;
                }
                @include mobile {
                    gap: 10px;
                    padding: 0px 40px 40px 0px;
                }

                .titles {
                    font-size: 13px;
                    color: $mainPink;
                    font-weight: 400;
                    letter-spacing: 1.3px;
                    font-family: 'Roboto', sans-serif;
                }    

                h3 {
                    font-size: 28px;
                    font-weight: 300;
                    letter-spacing: 0.6px;
                    color: $blackHeading;
                
                    @include large {
                        margin-right: 0px;
                        margin-bottom: 10px;
                    }
                    @include tablet {
                        margin-right: 0px;
                        margin-bottom: 10px;
                    }
                    @include mobile {
                        font-size: 24px;
                    }
                }

                span {
                    color: $mainBlack;
                    font-size: 17px;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;

                    @include large {
                        margin-right: 80px;
                    }
                    @include tablet{
                        margin-right: 20px;
                    }
                    @include mobile {
                        margin-right: 0px;
                    }
                    @include phone {
                        font-size: 18.5px;
                    }
                }

                .link {
                    color: inherit;
                    text-decoration: none;
                }

                .apply {
                    gap: 15px;
                    display: flex;
                    height: 45px;
                    width: 50%;
                    border: none;
                    cursor: pointer;
                    margin-top: 20px;
                    color: $white;
                    align-items: center;
                    border-radius: 5px;
                    justify-content: center;
                    background-color: $mainGreen;
                    @include mobile{
                        margin-top: 10px;
                    }
                    @include phone {
                        width: 70%;
                    }

                    p {
                        font-size: 15px;
                        font-weight: 300;
                        letter-spacing: 0.6px;
                        font-family: 'Roboto', sans-serif;
                        @include mobile {
                            font-size: 14.5px;
                        }
                        @include phone {
                            font-size: 15.5px;
                        }
                        &:hover{
                            font-weight: 400;
                        }
                    }

                    .applyIcon {
                        margin-top: 3px;
                        font-size: 22px;
                    }

                    &:hover {
                        color: $mainPink;
                        font-weight: 400;
                        border: 0.4px solid $mainPink;
                        background-color: $lightPink;
                    }

                }

            }

            .right {
                flex: 1;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    // width: 500px;
                    // height: 380px;
                    width: 100%;
                    border: none;
                    border-radius: 20px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    @include extralarge {
                        // height: 320px;
                    }
                    @include large {
                        width: 100%;
                        height: 450px;
                        object-fit: cover;
                    }
                    @include tablet {
                        height: 400px;
                    }
                    @include mobile {
                        height: 300px;
                    }
    
                }


            }

        }

    }

}
