@import "../../global.scss";

.navbar {
    top: 0;
    height: 90px;
    width: 100%;
    display: flex;
    z-index: 999;
    position: sticky;
    padding: 0px 60px;
    align-items: center;
    border-bottom: 1px solid  $lightGray2;
    justify-content: space-between;
    background-color: $white;

    @include extralarge{
        // background-color: gray;
    }
    @include large{
        // background-color: lightgreen;
    }
    @include tablet{
        // background-color: orange;
    }
    @include mobile{
        // background-color: yellow;
    }

    .link {
        color: inherit;
        text-decoration: none;
    }
    .itemIcon{
        font-size: 16px;
    }

    @include large {
        padding: 10px 30px;
    }

    @include mobile {
        padding: 10px 10px;
    }

    @include phone {
        padding: 10px 10px;
    }


    .link {
        gap: 5px;
        display: flex;
        color: inherit;
        align-items: center;
        text-decoration: none;

        .icon {
            font-size: 20px;
        }
    }

    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .logo {
                display: flex;
                align-items: center;
                margin-top: 8px;
                width: 130px;
                cursor: pointer;

            }

        }

        .right {
            gap: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .navbar-links {
                height: 100%;
                display: flex;
                align-items: center;


                .navList {
                    gap: 15px;
                    height: 100%;
                    display: flex;
                    list-style: none;
                    align-items: center;

                    .navItem {
                        height: 100%;
                        display: flex;
                        cursor: pointer;
                        font-size: 18.5px;
                        color: $mainGreen;
                        align-items: center;

                        span{
                            font-family: Futura-Regular;
                        }

                        &:hover {
                            color: $mainPink;
                        }
                    }


                    @include large {
                        display: none;
                    }
                }

            }

            .buttons {
                gap: 10px;
                display: flex;
                list-style: none;
                text-transform: capitalize;

                @include large {
                    display: none;
                }

                .donateBtn {
                    display: flex;
                    border: none;
                    color: $white;
                    font-size: 18px;
                    padding: 8px 25px;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    border: 0.4px solid $mainPink;
                    background-color: $mainPink;
                    font-family: Futura-Regular;

                }
            }

        }

    }


    .hamburger {
        display: none;
        width: 32px;
        height: 24px;
        margin-right: 10px;
        cursor: pointer;
        align-items: center;

        @include large {
            gap: 5px;
            display: flex;
            flex-direction: column;

            .icon {
                width: 100%;
                color: $mainPink;
                font-size: 115px;
                cursor: pointer;
            }

        }

    }

    .menu {
        top: 90px;
        margin: 0;
        width: 100vw;
        z-index: 999;
        display: none;
        position: fixed;
        padding: 50px 0px;
        list-style: none;
        color: $mainGreen;
        flex-direction: column;
        transition: all 1s ease;
        height: calc(100vh - 70px);
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px;

        @include large {
            display: flex;
        }

        .menuItem {
            gap: 10px;
            width: 100%;
            display: flex;
            cursor: pointer;
            padding: 15px 30px;
            align-items: center;
            border: 0.2px solid $lightGray2;
            justify-content: space-between;

            &:hover {
                color: $mainPink;
            }

            &:focus {
                color: $mainPink;
            }

            span{
                font-size: 18.5px;
                letter-spacing: 0.5px;
                font-family: Futura-Medium;
                @include phone{
                    font-size: 20px;
                }
            }

            .dropIcon {
                color:  $gray;
                font-size: 14px;
            }
        }


        .menuItem3 {
            display: flex;
            cursor: pointer;
            padding: 10px 30px;
            align-items: center;

            span{
                font-size: 20px;
            }

        }

        .options {
            display: flex;
            flex-direction: column;
            background-color: $lightPink;

            .menuItem2 {
                color: $mainGreen;
                font-size: 18px;
                cursor: pointer;
                padding: 15px 30px;
                border: 0.2px solid $lightGray2;

                &:hover {
                    color: $mainPink;
                }

                &:focus {
                    color: $mainPink;
                }

                span{
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    font-family: Futura-Regular;
                    @include phone{
                        font-size: 20px;
                    }
                }


            }

        }

        .donateBtn2 {
            display: flex;
            border: none;
            font-size: 18px;
            padding: 10px 25px;
            color: $white;
            margin-top: 20px;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            text-decoration: none;
            background-color: $mainPink;
            font-family: Futura-Regular;

            @include phone{
                font-size: 20px;
                padding: 15px 25px;
            }
        }
    }
}











