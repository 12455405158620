$offWhite: #FAF9F6;
$white: #FFFFFF;
$cream: #fff4f4;
$cream2: #F8F6F1;
$cream3: #DE3782;

$mainPink: #DE3782;
$lightPink: #f3ebe8;
$lightGreen: #0BDA51;
$mainGreen: #175E54;
$lightGreen: rgba(160, 214, 180,0.5);
$green: #3F6844;




// $niceBlue: #c5dfe0; //////////*********
$black: #1e1e29;
$blackHeading: #282828;
$subHeading: #3E4044;
$black2: rgba(40,40,40, 0.88);
$mainBlack: #363636;
$darkBlack: #001b2c;
$gray: #A9A9A9;
$lightGray: rgba(211,211,211, 0.3);
$lightGray2: rgba(211,211,211, 0.4);
$lightGray3: rgba(211,211,211, 0.3);
$lighterGray: #c7c6c6;



$extrasmall: 480px; 
$small: 576px; 
$medium: 768px; 
$large: 980px;  
$extralarge: 1100px;  

@mixin phone {
      @media (max-width: #{$extrasmall}) {
            @content
      }
};

@mixin mobile {
      @media (max-width: #{$small}) {
            @content
      }
};

@mixin tablet {
      @media (max-width: #{$medium}) {
            @content
      }
};

@mixin large {
      @media (max-width: #{$large}) {
            @content
      }
};

@mixin extralarge {
      @media (max-width: #{$extralarge}) {
            @content
      }
};