@import '../global.scss';

//INFO
.info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .wrapper{
        gap: 40px;
        width: 100%;
        display: flex;
        padding: 10px;
        margin-top: -10px;
        justify-content: space-between;

        @include extralarge{
            gap: 0px;
        }
        
        @include large{
            gap: 50px;
            padding: 0px;
            flex-direction: column;
        }
        
        .textWrapper{
            flex: 1.2;
            gap: 15px;
            display: flex;
            padding: 0px 80px 0px 0px;
            flex-direction: column;

            @include tablet{
                padding: 0px 0px 0px 0px;
            }

            span {
                font-size: 18px;
                font-weight: 300;
                line-height: 1.5;
                color: $darkBlack;
                letter-spacing: 0.5px;
                font-family: Futura-Light;
            }

        }

        .imageWrapper{
            flex: 1;
            display: flex;
            color: $white;
            flex-direction: column;
            border-radius: 15px;

            @include large{
                // width: 500px;
            }

            img {
                flex: 1;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;

                @include large{
                    width: 500px;
                }
                @include tablet{
                    width: 100%;
                    height: 100%;
                }
            }

        }

    }

}

//MODULES
.module {
    display: flex;
    padding: 0px 0px;
    align-items: flex-start;
    flex-direction: column;

    h3 {
        font-size: 21px;
        margin-bottom: 20px;
        color: $darkBlack;
        letter-spacing: 0.5px;
        font-family: Futura-Regular;
        @include mobile {
            font-size: 20px;
        }
        @include phone {
            font-size: 21px;
        }
    }

    .mainWrapper {
        gap: 80px;
        width: 100%;
        display: flex;
        padding: 10px;
        margin-top: -10px;
        justify-content: space-between;

        @include large{
            gap: 40px;
        }

        @include tablet{
            gap: 20px;
            padding: 0px;
            flex-direction: column;
        }

        .wrapper {
            gap: 20px;
            flex: 1.6;
            display: flex;
            flex-direction: column;

            p{
                color: $darkBlack;
                font-size: 17px;
                line-height: 1.5;
                letter-spacing: 0.5px;
                font-family: Futura-Light;

                @include phone{
                    font-size: 18.5px;
                }
            }

            span{
                color: $darkBlack;
                font-size: 17px;
                line-height: 1.5;
                letter-spacing: 0.5px;
                @include phone{
                    font-size: 18.5px;
                }
            }


            .timeTable {
                display: flex;
                flex-direction: column;

                .module-lists {
                    gap: 8px;
                    display: flex;
                    margin-top: -5px;
                    margin-left: 15px;
                    margin-bottom: 10px;
                    flex-direction: column;

                    li {
                        color: $darkBlack;
                        font-size: 17px;
                        line-height: 1.5;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                        @include phone{
                            font-size: 18px;
                        }
                    }

                }
            }
        }

        .outcome {
            flex: 1;
            display: flex;
            color: $white;
            flex-direction: column;
            border-radius: 15px;
            padding: 30px 10px;
            background-color: #007848;
            background-color: $mainGreen;

            h3 {
                color: $white;
                font-size: 16.5px;
                line-height: 1.5;
                margin-left: 15px;
                letter-spacing: 0.5px;
                @include phone{
                    font-size: 18.5px;
                }
            }

            .items {
                gap: 15px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .info-item {
                    gap: 10px;
                    display: flex;
                    padding: 10px;
                    width: 100%;
                    align-items: center;

                    .icon {
                        flex: 0.1;
                        font-size: 20px;
                    }

                    p {
                        flex: 1.8;
                        color: $white;
                        font-size: 16px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                        font-family: Futura-Light;
                        @include phone{
                            font-size: 18px;
                        }
                    }

                }

            }

        }

        .results {
            flex: 1;
            display: flex;
            color: $white;
            flex-direction: column;
            border-radius: 15px;
            padding: 30px 20px;
            background-color: $mainPink;

            h3 {
                color: $white;
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 20px;
            }

            .items {
                gap: 15px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .info-item {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .icon {
                        font-size: 15.5px;
                    }

                    p {
                        font-size: 15px;
                        font-weight: 300;
                    }

                }

            }

        }

    }

}


//CAREER WEEK
.career{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    

    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        font-family: 'Assistant', sans-serif;
    }

    .wrapper{
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: space-between;
    
        @include tablet{
            gap: 20px;
            flex-direction: column;
        }
        

        .requirements{
            flex: 1.5;
            display: flex;
            padding: 0px 80px 0px 0px;
            flex-direction: column;

            span {
                font-size: 15.5px;
                font-weight: 400;
                font-family: 'Assistant', sans-serif;
            }

            h3 {
                font-size: 17px;
                font-weight: 600;
                margin-top: 15px;
                font-family: 'Assistant', sans-serif;
            }

            .items {
                gap: 5px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
        
                .info-item {
                    gap: 10px;
                    display: flex;
                    margin-bottom: 10px;
        
                    span {
                        font-size: 15.5px;
                        font-weight: 300;
                        font-family: 'Assistant', sans-serif;
                    }
        
                    p {
                        font-size: 15.5px;
                        font-weight: 600;
                        font-family: 'Assistant', sans-serif;
                    }
        
                }
            }

            ul{
                gap: 10px;
                display: flex;
                margin-top: 30px;
                margin-left: 20px;
                flex-direction: column;
                li{
                    font-size: 16.5px;
                    font-family: 'Assistant', sans-serif;
                }
                
            }
        }

        .jobs{
            flex: 1;
            display: flex;
            color: $white;
            flex-direction: column;
            border-radius: 15px;
            padding: 40px 30px;
            background-color: $green;

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
                font-family: 'Assistant', sans-serif;
            }

            ul{
                gap: 10px;
                display: flex;
                flex-direction: column;
                li{
                    font-size: 15.5px;
                    font-family: 'Assistant', sans-serif;
                }
                
            }

            .items {
                gap: 5px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
        
                .info-item {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
        
                    .icon {
                        font-size: 15.5px;
                    }
        
                    p {
                        font-size: 15.5px;
                        font-weight: 300;
                        font-family: 'Assistant', sans-serif;
                    }
        
                }
            }


        }
    }

}
