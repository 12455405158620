@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Familjen+Grotesk:wght@400;500;600;700&display=swap');

@import './global.scss';

@font-face {
    font-family: Futura-Regular ;
    font-style: 'normal';
    src: url(./fonts/FuturaBook.ttf);
}
@font-face {
    font-family: Futura-Medium;
    font-style: 'normal';
    src: url(./fonts/FuturaMedium.ttf);
}
@font-face {
    font-family: Futura-Bold ;
    font-style: 'normal';
    src: url(./fonts/FuturaHeavy.ttf);
}
@font-face {
    font-family: Futura-light ;
    font-style: 'normal';
    // src: url(./fonts/FuturaLight.ttf);
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;

    font-family: Lora,serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-family: Futura,sans-serif;
}

.app {
    display: flex;
    justify-content: center;

    .link {
        color: inherit;
        text-decoration: none;
    }

    .container {
        width: 1200px;
        // background-color: $white;

        // @include large {
        //     width: 960px;
        // }

        // @include tablet {
        //     width: 720px;
        // }

        // @include mobile {
        //     width: 576px;
        // }

        a {
            color: inherit;
            text-decoration: none;
        }



    }
}